import $ from "jquery";
import '../../assets/libs/bootstrap-5.0.2-dist/js/bootstrap.bundle.min.js';
import * as AOS from '../../assets/libs/aos';
import Footer from '@/components/footer/Footer'
import Header from '@/components/header/header';

import {disSearch,apiGet,_err,disLoc} from '../../services/api'
export default {
    name: "Sale",
    data() {
        return {
            lists:[],
            lnglat : [],
        }
    },
    methods: {
      async handleBlur(e) {
        if(e.target.value){
            this.getLists(e.target.value)
        }else{
            _err('Missing massage')
        }
      },
      async getLists(data){
        let params = {
            page : 1,
            limit : 1000,
            keyword : '',
            sort : 'ASC',
        }
        
        if(data){
            params.keyword = data   
            params.limit = 1000
            params.sort = 'ASC'
            params.page = 1
            
        }
       
        this.lists = await apiGet(disSearch,params)
      },
      async getLoc(e) {
        console.log(e)
        if(this.lnglat.length==2){
           
            let params = {
                page : 1,
                limit : 1000,
                keyword : '',
                sort : 'ASC',
            }
            
            this.lists = await apiGet(disSearch,params)
        }else{
            _err('Missing massage')
        }
      },
      open(path){
        window.open(path, '_blank');
      },
    },
    mounted () {
        document.title = "AGC - Sale";

        AOS.init();
        resize();
        function resize(){
            if(window.innerWidth<800) $('.footer').css({'position':'relative','top':'0px'});
        }
        this.$getLocation({
            enableHighAccuracy: true, //defaults to false
            timeout: 5000, //defaults to Infinity
            maximumAge: 0 //defaults to 0
            
        })
        .then(coor => {
            this.lnglat=[coor.lng,coor.lat]
            this.getLoc(this.lnglat)
        }).catch((error)=> {
            // handle error
            console.log(error);
            this.getLists()
            
        });
    },
    components: {
        Footer,Header
    }
};